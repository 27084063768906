import React from "react";

import { useRouteError } from "react-router-dom";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export const ErrorPage = (): JSX.Element => {
  const error = useRouteError() as any;
  return (
    <Stack id="no-match-page">
      <Typography variant="h4" textAlign="center">
        Error Page
      </Typography>
      {error?.statusText && (
        <Typography variant="h5" textAlign="center">
          {error.statusText}
        </Typography>
      )}
      {error?.message && (
        <Typography variant="h5" textAlign="center">
          {error.message}
        </Typography>
      )}
    </Stack>
  );
};
