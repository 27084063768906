import React from "react";

import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import MobileStepper from "@mui/material/MobileStepper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

enum Steps {
  FirstStep = 0,
  ConfirmWeight = 0,
  ChooseMedication = 1,
  SelectStartDay = 2,
  AddPreviousDays = 3,
  AddLastInr = 4,
  MedicationDose = 5,
  lastStep = 5,
}

export const AddMedication = (): JSX.Element => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = React.useState<Steps>(Steps.ConfirmWeight);

  const handleSubmit = () => {
    navigate("/account/home");
  };

  const handleNext = (step: Steps) => {
    if (step === Steps.lastStep) {
      handleSubmit();
    } else {
      switch (step) {
        case Steps.ConfirmWeight:
          setActiveStep(Steps.ChooseMedication);
          break;
        case Steps.ChooseMedication:
          setActiveStep(Steps.SelectStartDay);
          break;
        case Steps.SelectStartDay:
          setActiveStep(Steps.AddPreviousDays);
          break;
        case Steps.AddPreviousDays:
          setActiveStep(Steps.AddLastInr);
          break;
        case Steps.AddLastInr:
          setActiveStep(Steps.MedicationDose);
          break;
      }
    }
  };

  const handleBack = (step: Steps) => {
    switch (step) {
      case Steps.ConfirmWeight:
        setActiveStep(Steps.ConfirmWeight);
        break;
      case Steps.ChooseMedication:
        setActiveStep(Steps.ConfirmWeight);
        break;
      case Steps.SelectStartDay:
        setActiveStep(Steps.ChooseMedication);
        break;
      case Steps.AddPreviousDays:
        setActiveStep(Steps.SelectStartDay);
        break;
      case Steps.AddLastInr:
        setActiveStep(Steps.AddPreviousDays);
        break;
      case Steps.MedicationDose:
        setActiveStep(Steps.AddLastInr);
        break;
    }
  };

  const getNextLabel = (): string => {
    return activeStep === Steps.lastStep ? "Finish" : "Next";
  };
  const getBackLabel = (): string => {
    return "Back";
  };

  return (
    <Stack direction="column" justifyContent="space-between" sx={{ height: "300px" }}>
      {activeStep === Steps.ConfirmWeight && (
        <React.Fragment>
          <Stack alignItems="center">
            <Typography>Confirm weight</Typography>
          </Stack>
          <Stack px={2}>
            <Stack>Did your weight changed ? - My weight</Stack>
          </Stack>
        </React.Fragment>
      )}
      {activeStep === Steps.ChooseMedication && (
        <React.Fragment>
          <Stack alignItems="center">
            <Typography>Choose medication</Typography>
          </Stack>
          <Stack px={2}>
            <Stack>Did your weight changed ? - My weight</Stack>
          </Stack>
        </React.Fragment>
      )}
      {activeStep === Steps.SelectStartDay && (
        <React.Fragment>
          <Stack alignItems="center">
            <Typography>Medication start day</Typography>
          </Stack>
          <Stack px={2}>
            <Stack>Is today the first day?</Stack>
          </Stack>
        </React.Fragment>
      )}
      {activeStep === Steps.AddPreviousDays && (
        <React.Fragment>
          <Stack alignItems="center">
            <Typography>Add Previous Day</Typography>
          </Stack>
          <Stack px={2}>
            <Stack>Add Previous Day</Stack>
          </Stack>
        </React.Fragment>
      )}
      {activeStep === Steps.AddLastInr && (
        <React.Fragment>
          <Stack alignItems="center">
            <Typography>Add INR</Typography>
          </Stack>
          <Stack px={2}>
            <Stack>Add INR</Stack>
          </Stack>
        </React.Fragment>
      )}
      {activeStep === Steps.MedicationDose && (
        <React.Fragment>
          <Stack alignItems="center">
            <Typography>Medication dose</Typography>
          </Stack>
          <Stack px={2}>
            <Stack>Medication dose</Stack>
          </Stack>
        </React.Fragment>
      )}
      <MobileStepper
        variant="text"
        position="static"
        steps={Number(Steps.lastStep)}
        activeStep={Number(activeStep)}
        nextButton={
          <Button size="small" onClick={() => handleNext(activeStep)}>
            {getNextLabel()}
            {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button size="small" onClick={() => handleBack(activeStep)} disabled={activeStep === Steps.FirstStep}>
            {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            {getBackLabel()}
          </Button>
        }
      />
    </Stack>
  );
};
