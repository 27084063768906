import React from "react";

import { RouteObject } from "react-router-dom";

import { RequireAuth } from "./components/RequireAuth";
import { Login } from "./components/Login/Login";

import { Home } from "./components/Account/Home/Home";
import { AddMedication } from "./components/Account/AddMedication/AddMedication";
import { AddDose } from "./components/Account/AddDose/AddDose";
import { History } from "./components/Account/History/History";

import { Page } from "./components/Account/Page/Page";
import { SettingsPage } from "./components/Account/Settings/SettingsPage";
import { ProfilePage } from "./components/Account/Profile/ProfilePage";

import { Body } from "./components/Main/Body";

import { Layout } from "./Layout";
import { NoMatch } from "./components/NoMatch";
import { ErrorPage } from "./components/ErrorPage";

export const routes: RouteObject[] = [
  {
    id: "route-root",
    path: "/",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        id: "route-body",
        index: true,
        element: <Body />,
      },
      {
        id: "route-account",
        path: "/account",
        errorElement: <ErrorPage />,
        children: [
          {
            id: "route-account-nomatch",
            index: true,
            element: <NoMatch />,
          },
          {
            id: "route-account-addmedication",
            path: "/account/addmedication",
            element: (
              <RequireAuth>
                <AddMedication />
              </RequireAuth>
            ),
          },
          {
            id: "route-account-adddose",
            path: "/account/adddose",
            element: (
              <RequireAuth>
                <AddDose />
              </RequireAuth>
            ),
          },
          {
            id: "route-account-home",
            path: "/account/home",
            element: (
              <RequireAuth>
                <Home />
              </RequireAuth>
            ),
          },
          {
            id: "route-account-history",
            path: "/account/history",
            element: (
              <RequireAuth>
                <History />
              </RequireAuth>
            ),
          },
          {
            id: "route-account-page",
            path: "/account/page",
            element: (
              <RequireAuth>
                <Page />
              </RequireAuth>
            ),
          },
          {
            id: "route-account-profile",
            path: "/account/profile",
            element: (
              <RequireAuth>
                <ProfilePage />
              </RequireAuth>
            ),
          },
          {
            id: "route-account-settings",
            path: "/account/settings",
            element: (
              <RequireAuth>
                <SettingsPage />
              </RequireAuth>
            ),
          },
        ],
      },
    ],
  },
  {
    id: "route-auth",
    path: "/auth",
    element: <Layout />,
    errorElement: <ErrorPage />,
    children: [
      {
        id: "route-auth-nomatch",
        index: true,
        element: <NoMatch />,
      },
      {
        id: "route-auth-login",
        path: "/auth/login",
        element: <Login />,
      },
      {
        id: "route-auth-signup",
        path: "/auth/signup",
        element: <Login />,
      },
    ],
  },
  {
    id: "route-default",
    path: "*",
    element: <Layout />,
    children: [
      {
        id: "route-default-nomatch",
        index: true,
        path: "*",
        element: <NoMatch />, // 404
      },
    ],
  },
];
