import { DataStore, Predicates } from "aws-amplify";

import { Account, LazyAccount, Doctor, LazyDoctor, Medication } from "../models";

/*
export const updatePost = async (id, newTitle) => {
    const original = await DataStore.query(Post, id);
    await DataStore.save(
      Post.copyOf(original, updated => {
        updated.title = newTitle
      })
    );
  }
*/

const DEFAULT_DOCTOR_USERNAME = "00000000-0000-0000-0000-000000000000";
const DEFAULT_DOCTOR_FIRSTNAME = "default";
const DEFAULT_DOCTOR_LASTNAME = "default";
const DEFAULT_DOCTOR_GOVID = "0000000000"; // 10 zeros
const DEFAULT_DOCTOR_EMAIL = "default@default.net";
const DEFAULT_DOCTOR_PHONE = "+12136210002";

const makeDefaultDoctor = (): Doctor => {
  const newDoctor = new Doctor({
    username: DEFAULT_DOCTOR_USERNAME,
    firstName: DEFAULT_DOCTOR_FIRSTNAME,
    lastName: DEFAULT_DOCTOR_LASTNAME,
    govId: DEFAULT_DOCTOR_GOVID,
    email: DEFAULT_DOCTOR_EMAIL,
    phone: DEFAULT_DOCTOR_PHONE,
  });
  return newDoctor;
};

export const addDefaultDoctor = async (): Promise<void> => {
  const doctorRecord = await DataStore.query(Doctor, DEFAULT_DOCTOR_USERNAME);
  if (doctorRecord === undefined) {
    const newDoctor = makeDefaultDoctor();
    await DataStore.save(newDoctor).then((item) => {
      console.info("Default Doctor added %s", JSON.stringify(item, null, 2));
    });
  } else {
    console.info("Default Doctor already exists %s", JSON.stringify(doctorRecord, null, 2));
  }
};

export const deleteDefaultDoctor = async (): Promise<void> => {
  await DataStore.delete(Doctor, DEFAULT_DOCTOR_USERNAME).then(() => {
    console.info("Default Doctor deleted");
  });
};

const makeDoctorData = (doctor: Doctor | undefined, values: LazyDoctor): Doctor => {
  if (doctor !== undefined) {
    const updatedDoctor = Doctor.copyOf(doctor, (updated) => {
      if (values.firstName !== doctor.firstName) {
        updated.firstName = values.firstName;
      }
      if (values.lastName !== doctor.lastName) {
        updated.lastName = values.lastName;
      }
      if (values.govId !== doctor.govId) {
        updated.govId = values.govId;
      }
      if (values.email !== doctor.email) {
        updated.email = values.email;
      }
      if (values.phone !== doctor.phone) {
        updated.phone = values.phone;
      }
    });
    console.info("SUCCESS saving UPDATED doctor=%s", JSON.stringify(updatedDoctor, null, 2));
    return updatedDoctor;
  } else {
    const newDoctor = makeDefaultDoctor();
    console.info("SUCCESS saving NEW doctor=%s", JSON.stringify(newDoctor, null, 2));
    return newDoctor;
  }
};

export const updateDoctor = async (doctor: Doctor | undefined, values: LazyDoctor): Promise<void> => {
  const doctorData = makeDoctorData(doctor, values);
  const savedDoctor = await DataStore.save(doctorData);
  console.info("SUCCESS saved doctor=%s", JSON.stringify(savedDoctor, null, 2));
};

export const loadAccount = async (username: string) => {
  const accountRecord = await DataStore.query(Account, username);
  if (accountRecord !== undefined) {
    const doctorRecord = await accountRecord.doctor;
    console.info("LOADING initial accountRecord=%s", JSON.stringify(accountRecord, null, 2));
    console.info("LOADING initial doctorRecord=%s", JSON.stringify(doctorRecord, null, 2));
    return { accountRecord, doctorRecord };
  }
  const doctorRecord = await DataStore.query(Doctor, DEFAULT_DOCTOR_USERNAME);
  return { accountRecord, doctorRecord };
};

export const makeAccountData = (
  username: string,
  email: string,
  account: Account | undefined,
  values: LazyAccount,
  doctor: Doctor
): Account => {
  if (account !== undefined) {
    const updatedAccount = Account.copyOf(account, (updated) => {
      if (values.email !== account.email) {
        updated.email = values.email;
      }
      if (values.firstName !== account.firstName) {
        updated.firstName = values.firstName;
      }
      if (values.lastName !== account.lastName) {
        updated.lastName = values.lastName;
      }
      if (values.clinicalSex !== account.clinicalSex) {
        updated.clinicalSex = values.clinicalSex;
      }
      if (values.dateOfBirth !== account.clinicalSex) {
        updated.dateOfBirth = values.dateOfBirth;
      }
      if (values.govId !== account.govId) {
        updated.govId = values.govId;
      }
      if (values.phone !== account.phone) {
        updated.phone = values.phone;
      }
      if (values.measurementSystem !== account.measurementSystem) {
        updated.measurementSystem = values.measurementSystem;
      }
      if (values.weight !== account.weight) {
        updated.weight = values.weight;
      }
      if (values.height !== account.height) {
        updated.height = values.height;
      }
    });
    console.info("SUCCESS saving UPDATED account=%s", JSON.stringify(updatedAccount, null, 2));
    return updatedAccount;
  } else {
    const newAccount = new Account({
      username: username,
      doctorId: doctor.username,
      doctor: doctor,
      email: email,
      firstName: values.firstName,
      lastName: values.lastName,
      clinicalSex: values.clinicalSex,
      dateOfBirth: values.dateOfBirth,
      govId: null,
      phone: null,
      measurementSystem: null,
      weight: null,
      height: null,
    });
    console.info("SUCCESS saving NEW account=%s", JSON.stringify(newAccount, null, 2));
    return newAccount;
  }
};

export const updateAccount = async (
  username: string,
  email: string,
  account: Account | undefined,
  values: LazyAccount,
  doctor: Doctor
): Promise<void> => {
  const accountData = makeAccountData(username, email, account, values, doctor);
  const savedAccount = await DataStore.save(accountData);
  console.info("SUCCESS saved account=%s", JSON.stringify(savedAccount, null, 2));
};

export const clearDatastore = async (): Promise<void> => {
  try {
    await DataStore.clear().then(() => {
      console.info("DataStore clear done");
    });
  } catch (err) {
    console.info("clearDatastore error=%s", err);
  }
};

export const deleteAllRecords = async (): Promise<void> => {
  try {
    // delete all
    await DataStore.delete(Account, Predicates.ALL).then(() => {
      console.info("Account delete all");
    });
    await DataStore.delete(Medication, Predicates.ALL).then(() => {
      console.info("Medication delete all");
    });
  } catch (err) {
    console.info("deleteAllRecords error=%s", err);
  }
};

export const dumpAll = async (): Promise<void> => {
  try {
    const doctorRecords = await DataStore.query(Doctor, Predicates.ALL);
    console.info("SUCCESS query Doctor records=%s", JSON.stringify(doctorRecords, null, 2));
    const accountRecords = await DataStore.query(Account, Predicates.ALL);
    console.info("SUCCESS query Account records=%s", JSON.stringify(accountRecords, null, 2));
    const medicationRecords = await DataStore.query(Medication, Predicates.ALL);
    console.info("SUCCESS query Medication records=%s", JSON.stringify(medicationRecords, null, 2));
  } catch (err) {
    console.info("dumpAll error=%s", err);
  }
};
