import React from "react";

import { translate } from "@aws-amplify/ui";

import { PaletteMode } from "@mui/material";
import { styled } from "@mui/material/styles";
import DarkModeIcon from "@mui/icons-material/DarkMode";
import LightModeIcon from "@mui/icons-material/LightMode";
import SettingsBrightnessIcon from "@mui/icons-material/SettingsBrightness";
import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";

import { useAppContext } from "../AppContext";

const IconToggleButton = styled(ToggleButton)({
  display: "flex",
  justifyContent: "center",
  width: "100%",
  "& > *": {
    marginRight: "8px",
  },
});

export const ColorModeSwitcher = (): JSX.Element => {
  const appContext = useAppContext();

  return (
    <ToggleButtonGroup
      value={appContext.colorMode}
      size="small"
      onChange={(event: React.MouseEvent<HTMLElement>, newAlignment: string | null) =>
        appContext.setColorMode(newAlignment as PaletteMode | "system")
      }
      exclusive={true}
      className="colormode-switcher"
      orientation="horizontal"
    >
      <IconToggleButton value="light" title={translate("LIGHT_MODE")}>
        <LightModeIcon fontSize="small" />
        {translate("LIGHT_MODE_SHORT")}
      </IconToggleButton>
      <IconToggleButton value="system" title={translate("SYSTEM_PREFERENCES")}>
        <SettingsBrightnessIcon fontSize="small" />
        {translate("SYSTEM_PREFERENCES_SHORT")}
      </IconToggleButton>
      <IconToggleButton value="dark" title={translate("DARK_MODE")}>
        <DarkModeIcon fontSize="small" />
        {translate("DARK_MODE_SHORT")}
      </IconToggleButton>
    </ToggleButtonGroup>
  );
};
