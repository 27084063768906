import React from "react";

import { Outlet } from "react-router-dom";

import { useAuthenticator } from "@aws-amplify/ui-react";

import CircularProgress from "@mui/material/CircularProgress";
import { ResponsiveAppBar } from "./components/Navigation/ResponsiveAppBar";
import { FixedBottomNavigation } from "./components/Navigation/FixedBottomNavigation";

export const Layout: React.FC = (): JSX.Element => {
  const { user, route } = useAuthenticator((context) => [context.route]);

  return (
    <React.Fragment>
      <ResponsiveAppBar />
      {route === "idle" && user?.username === undefined ? <CircularProgress /> : <Outlet />}
      <FixedBottomNavigation />
    </React.Fragment>
  );
};
