import React from "react";

import { translate } from "@aws-amplify/ui";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export const History: React.FC = (): JSX.Element => {
  return (
    <Stack>
      <Typography variant="h6" textAlign="center">
        {translate("MY_MEDICATIONS_HISTORY")}
      </Typography>
    </Stack>
  );
};
