import React from "react";

import { translate } from "@aws-amplify/ui";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export const Introduction: React.FC = (): JSX.Element => {
  return (
    <Stack>
      <Typography variant="h5" textAlign="center">
        {translate("INTRODUCTION")}
      </Typography>
      <Typography variant="body2" textAlign="center">
        {translate("BODY")}
      </Typography>
    </Stack>
  );
};
