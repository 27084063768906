import React from "react";

import { translate } from "@aws-amplify/ui";

import { useNavigate, useLocation } from "react-router";

import { Authenticator, useAuthenticator } from "@aws-amplify/ui-react";

import { CheckboxField, View } from "@aws-amplify/ui-react";

type NoProps = Record<string, unknown>;

export const Login: React.FC<NoProps> = (): JSX.Element => {
  const { route } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const signUp = location.pathname.endsWith("/signup");
  const navigate = useNavigate();
  const from = location.state?.from?.pathname || "/";
  React.useEffect(() => {
    if (route === "authenticated") {
      navigate(from, { replace: true });
    }
  }, [route, navigate, from]);

  return (
    <View key={location.key}>
      {signUp ? (
        <Authenticator
          key={location.key}
          initialState="signUp"
          // Customize `Authenticator.SignUp.FormFields`
          components={{
            SignUp: {
              FormFields() {
                const { validationErrors } = useAuthenticator();

                return (
                  <React.Fragment>
                    {/* Re-use default `Authenticator.SignUp.FormFields` */}
                    <Authenticator.SignUp.FormFields />

                    {/* Append & require Terms & Conditions field to sign up  */}
                    <CheckboxField
                      errorMessage={validationErrors.acknowledgement as string}
                      hasError={!!validationErrors.acknowledgement}
                      name="acknowledgement"
                      value="yes"
                      label={translate("I_AGREE_TERMS_CONDITIONS")}
                    />
                  </React.Fragment>
                );
              },
            },
          }}
          services={{
            async validateCustomSignUp(formData) {
              if (!formData.acknowledgement) {
                return {
                  acknowledgement: translate("MUST_AGREE_TERMS_CONDITIONS"),
                };
              }
            },
          }}
        />
      ) : (
        <Authenticator key={location.key} initialState="signIn" />
      )}
    </View>
  );
};
