export const pl_PL = {
  APP_VERSION: "Wersja aplikacji",
  NEW_APP_VERSION_AVAILABLE: "Dostępna jest nowa wersja tej aplikacji",
  UPDATE_NOW: "AKTUALIZUJ TERAZ",
  CLEAR_DATASTORE: "Wyczyść magazyn danych",

  CANCEL: "Anuluj",
  SAVE: "Zapisz",
  HOME: "Strona domowa",
  DASHBOARD: "Panel",
  HISTORY: "Historia",
  ACCOUNT: "Moje konto",
  PROFILE: "Mój profil",
  PROFILE_TOOLTIP: "Profil i konto",
  SETTINGS: "Ustawienia",
  SETTINGS_TOOLTIP: "Przełącz szufladę ustawień",
  LOGOUT: "Wyloguj",
  CREATE_ACCOUNT: "Utwórz konto",
  SIGN_IN: "Zaloguj się",
  ADDMEDICATION: "Dodaj lek",
  ADDDOSE: "Dodaj dawkę",

  MY_MEDICATIONS: "Moje leki",
  MY_MEDICATIONS_HISTORY: "Moja historia leczenia",

  PROFILE_SETTINGS: "Ustawienia profilu",
  ACCOUNT_SETTINGS: "Ustawienia konta",
  ACCOUNT_PAGE: "Konto",

  PROFILE_DESCRIPTION: "Podaj informacje o wadze i wzroście",
  SETTINGS_DESCRIPTION: "Podaj swoje dane osobowe",

  MODE: "Tryb",
  LIGHT_MODE: "Tryb światła",
  SYSTEM_PREFERENCES: "Preferencje systemu",
  DARK_MODE: "Tryb ciemny",
  LIGHT_MODE_SHORT: "Jasny",
  SYSTEM_PREFERENCES_SHORT: "System",
  DARK_MODE_SHORT: "Ciemny",

  SUBMIT: "Prześlij",

  INTRODUCTION: "Wprowadzenie",
  BODY: "Normalny mechanizm krzepnięcia jest złożonym procesem obejmującym wiele substancji (czynników krzepnięcia). Czynniki te są wytwarzane przez wątrobę i działają kolejno, tworząc skrzep krwi. Aby wątroba mogła wytwarzać niektóre czynniki krzepnięcia, musi być dostępna odpowiednia ilość witaminy K. Warfaryna blokuje jeden z enzymów, który wykorzystuje witaminę K do wytwarzania niektórych czynników krzepnięcia, co z kolei zmniejsza ich zdolność do prawidłowego działania we krwi. W rezultacie mechanizm krzepnięcia zostaje zakłócony i krzepnięcie krwi trwa dłużej.",

  IF_LEARN_MORE: "Jeśli chcesz dowiedzieć się więcej.",
  PLEASE: "Proszę",
  I_AGREE_TERMS_CONDITIONS: "Zgadzam się z warunkami",
  MUST_AGREE_TERMS_CONDITIONS: "Musisz zaakceptować warunki",

  // ProfileForm
  HEIGHT_IS_REQUIRED: "Wymagany jest wzrost osoby",
  HEIGHT: "Wzrost ",
  MEASUREMENT_SYSTEM_IS_REQUIRED: "Wymagana jest system miary",
  SELECT_MEASUREMENT_SYSTEM: "Wybierz system miary",
  METRIC: "Metryczna",
  MUST_BE_MORE_THEN_0: "usi być więcej niż 0",
  MUST_SPECIFY_NUMBER: "Należy podać liczbę",
  IMPERIAL: "Imperialna",
  WEIGHT_REQUIRED: "Wymagana jest waga osoby",
  WEIGHT: "Waga",

  // SettingsForm
  FIRSTNAME_IS_REQUIRED: "Pierwsze imię jest wymagane",
  LASTNAME_IS_REQUIRED: "Nazwisko jest wymagane",
  CLINICALSEX_IS_REQUIRED: "Seks kliniczny jest wymagany",
  DATEOFBIRTH_IS_REQUIRED: "Data urodzenia jest wymagana",
  DATEOFBIRTH_MUST_BE_18: "Musisz mieć ukończone 18 lat",
  FEMALE_SEX: "Płeć żeńska",
  MALE_SEX: "Płeć męska",
  FIRST_NAME: "Pierwsze imię",
  LAST_NAME: "Nazwisko",
  CLINICALSEX: "Seks kliniczny",
  DATEOFBIRTH: "Data urodzenia",
};
