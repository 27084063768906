import React from "react";

import { Link } from "react-router-dom";

import { translate } from "@aws-amplify/ui";

import { useAuthenticator } from "@aws-amplify/ui-react";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";

import { Introduction } from "./Introduction";

type NoProps = Record<string, unknown>;

export const Body: React.FC<NoProps> = (): JSX.Element => {
  const { route } = useAuthenticator((context) => [context.route]);

  return (
    <React.Fragment>
      <Introduction />;
      {route !== "authenticated" && (
        <React.Fragment>
          <Divider />
          <Typography textAlign="center">{translate("IF_LEARN_MORE")}</Typography>
          <Typography textAlign="center">
            {translate("PLEASE")} <Link to={"/auth/signup"}>{translate("CREATE_ACCOUNT")}</Link>
          </Typography>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};
