import React from "react";

import { useLocation, Navigate } from "react-router-dom";

import { useAuthenticator } from "@aws-amplify/ui-react";

type NoProps = Record<string, unknown>;

export const RequireAuth: React.FC<React.PropsWithChildren<NoProps>> = (
  props: React.PropsWithChildren<NoProps>
): JSX.Element => {
  const location = useLocation();
  const { route } = useAuthenticator((context) => [context.route]);
  if (route !== "authenticated") {
    return <Navigate to="/auth/login" state={{ from: location }} replace />;
  }
  return <React.Fragment>{props.children && props.children}</React.Fragment>;
};
