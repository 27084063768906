import React from "react";

import produce from "immer";

import { PaletteMode } from "@mui/material";

import { Doctor, Account, Medication } from "../models";

const appStateKey = "app-state";

export type AppState = {
  lastUpdatedTs: number;
};

// TODO: Enable on first release
/*
const saveAppStateToLocalStorage = (appState: AppState): void => {
  localStorage.setItem(appStateKey, JSON.stringify(appState));
};
*/

const defaultAppState = (): AppState => {
  const lastUpdatedTs = Date.now();
  const defaultState: AppState = {
    lastUpdatedTs: lastUpdatedTs,
  };
  return defaultState;
};

const decodeAppStateFromLocalStorage = (appStateStorage: string | null): AppState => {
  const defaultState: AppState = defaultAppState();
  if (appStateStorage) {
    const currentState = JSON.parse(appStateStorage) as AppState;
    if (currentState.lastUpdatedTs) {
      return currentState;
    }
  }
  return defaultState;
};

const storageVersionKey = "version";

const saveVersion = (value: string): void => {
  if (value !== "system") {
    localStorage.setItem("version", value);
  } else {
    localStorage.removeItem("version");
  }
};

const loadVersion = (): string => {
  const colorModePreference = localStorage.getItem(storageVersionKey) as string;
  if (colorModePreference) {
    return colorModePreference;
  }
  return "unknown";
};

const storageColorModeKey = "colorMode";

const saveColorMode = (value: PaletteMode | "system"): void => {
  if (value !== "system") {
    localStorage.setItem("colorMode", value);
  } else {
    localStorage.removeItem("colorMode");
  }
};

const loadColorMode = (): PaletteMode | "system" => {
  const colorModePreference = localStorage.getItem(storageColorModeKey) as PaletteMode | "system" | undefined;
  if (colorModePreference) {
    return colorModePreference;
  }
  return "system";
};

export interface AppContextState {
  firstTime: boolean;
  clearFirstTime(): void;

  justLoaded: boolean;
  clearJustLoaded(): void;

  showReload: boolean;
  setShowReload(value: boolean): void;

  reloadPage: boolean;
  setReloadPage(value: boolean): void;

  version: string;
  setVersion(value: string): void;

  colorMode: PaletteMode | "system";
  setColorMode(value: PaletteMode | "system" | undefined | null): void;

  appState: AppState;

  username: string | undefined;
  setUsername(value: string | undefined): void;

  email: string | undefined;
  setEmail(value: string | undefined): void;

  phoneNumber: string | undefined;
  setPhoneNumber(value: string | undefined): void;

  doctor: Doctor | undefined;
  setDoctor(value: Doctor): void;

  account: Account | undefined;
  setAccount(value: Account): void;

  medications: Medication[] | undefined;
  setMedication(value: Medication[]): void;

  clearData(): void;
}

const initAppContextState = (): AppContextState => {
  const appStateStorage = localStorage.getItem(appStateKey);

  /* eslint-disable @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function */
  const defaultAppContextState: AppContextState = {
    firstTime: appStateStorage ? false : true,
    clearFirstTime: (): void => {},

    justLoaded: appStateStorage ? true : false,
    clearJustLoaded: (): void => {},

    showReload: false,
    setShowReload: (value: boolean): void => {},

    reloadPage: false,
    setReloadPage: (value: boolean): void => {},

    version: loadVersion(),
    setVersion: (value: string): void => {},

    colorMode: loadColorMode(),
    setColorMode: (value: PaletteMode | "system" | undefined | null): void => {},

    appState: decodeAppStateFromLocalStorage(appStateStorage),

    username: undefined,
    setUsername: (value: string | undefined): void => {},

    email: undefined,
    setEmail: (value: string | undefined): void => {},

    phoneNumber: undefined,
    setPhoneNumber: (value: string | undefined): void => {},

    doctor: undefined,
    setDoctor: (value: Doctor): void => {},

    account: undefined,
    setAccount: (value: Account): void => {},

    medications: undefined,
    setMedication: (value: Medication[]): void => {},

    clearData: (): void => {},
  };
  /* eslint-enable @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function */

  return defaultAppContextState;
};

//const { Provider, Consumer } = React.createContext<AppContextState>({} as AppContextState);
export const AppContext = React.createContext<AppContextState>({} as AppContextState);

type NoProps = Record<string, unknown>;

class AppContextProvider extends React.Component<React.PropsWithChildren<NoProps>, AppContextState> {
  constructor(props: React.PropsWithChildren<NoProps>) {
    super(props);
    this.state = initAppContextState();
  }

  clearFirstTime = (): void => {
    this.setState(
      produce((draft) => {
        draft.firstTime = false;
        const lastUpdatedTs = Date.now();
        draft.appState.lastUpdatedTs = lastUpdatedTs;
        // TODO: Enable on first release
        //saveAppStateToLocalStorage(draft.appState);
      })
    );
  };

  clearJustLoaded = (): void => {
    this.setState(
      produce((draft) => {
        draft.justLoaded = false;
        const lastUpdatedTs = Date.now();
        draft.appState.lastUpdatedTs = lastUpdatedTs;
        // TODO: Enable on first release
        //saveAppStateToLocalStorage(draft.appState);
      })
    );
  };

  setShowReload = (value: boolean): void => {
    this.setState(
      produce((draft) => {
        draft.showReload = value;
      })
    );
  };

  setReloadPage = (value: boolean): void => {
    this.setState(
      produce((draft) => {
        draft.reloadPage = value;
      })
    );
  };

  setVersion = (value: string): void => {
    this.setState(
      produce((draft) => {
        draft.version = value;
        saveVersion(value);
      })
    );
  };

  setColorMode = (value: PaletteMode | "system" | undefined | null): void => {
    const colorMode: PaletteMode | "system" = value ? value : "system";
    this.setState(
      produce((draft) => {
        draft.colorMode = colorMode;
        saveColorMode(colorMode);
      })
    );
  };

  setUsername = (value: string | undefined): void => {
    this.setState(
      produce((draft) => {
        draft.username = value;
      })
    );
  };

  setEmail = (value: string | undefined): void => {
    this.setState(
      produce((draft) => {
        draft.email = value;
      })
    );
  };

  setPhoneNumber = (value: string | undefined): void => {
    this.setState(
      produce((draft) => {
        draft.phoneNumber = value;
      })
    );
  };

  setDoctor = (value: Doctor): void => {
    this.setState(
      produce((draft) => {
        draft.doctor = value;
      })
    );
  };

  setAccount = (value: Account): void => {
    this.setState(
      produce((draft) => {
        draft.account = value;
      })
    );
  };

  setMedication = (value: Medication[]): void => {
    this.setState(
      produce((draft) => {
        draft.medications = value;
      })
    );
  };

  clearData = (): void => {
    this.setState(
      produce((draft) => {
        draft.username = undefined;
        draft.email = undefined;
        draft.phoneNumber = undefined;
        draft.doctor = undefined;
        draft.account = undefined;
        draft.medications = undefined;
      })
    );
  };

  render(): React.ReactNode {
    return (
      <AppContext.Provider
        value={{
          firstTime: this.state.firstTime,
          clearFirstTime: this.clearFirstTime,

          justLoaded: this.state.justLoaded,
          clearJustLoaded: this.clearJustLoaded,

          showReload: this.state.showReload,
          setShowReload: this.setShowReload,

          reloadPage: this.state.reloadPage,
          setReloadPage: this.setReloadPage,

          version: this.state.version,
          setVersion: this.setVersion,

          colorMode: this.state.colorMode,
          setColorMode: this.setColorMode,

          appState: this.state.appState,

          username: this.state.username,
          setUsername: this.setUsername,

          email: this.state.email,
          setEmail: this.setEmail,

          phoneNumber: this.state.phoneNumber,
          setPhoneNumber: this.setPhoneNumber,

          doctor: this.state.doctor,
          setDoctor: this.setDoctor,

          account: this.state.account,
          setAccount: this.setAccount,

          medications: this.state.medications,
          setMedication: this.setMedication,

          clearData: this.clearData,
        }}
      >
        {this.props.children && this.props.children}
      </AppContext.Provider>
    );
  }
}

export const useAppContext = (): AppContextState => {
  return React.useContext(AppContext);
};

export { AppContextProvider };
