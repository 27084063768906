import React from "react";

import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

export const NoMatch = (): JSX.Element => {
  return (
    <Stack id="no-match-page">
      <Typography variant="h4" textAlign="center">
        Oops!
      </Typography>
      <Typography variant="h5" textAlign="center">
        NoMatch 404
      </Typography>
    </Stack>
  );
};
