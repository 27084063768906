import React from "react";

import { useSnackbar, SnackbarKey } from "notistack";

import { translate } from "@aws-amplify/ui";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";

import { useAppContext } from "./AppContext";

export const VersionMonitor = (): JSX.Element => {
  const appContext = useAppContext();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const handleClose = React.useCallback(
    (snackbarId: SnackbarKey, reason?: string) => {
      if (reason !== "clickaway") {
        closeSnackbar(snackbarId);
      }
    },
    [closeSnackbar]
  );

  const handleUpdateReload = React.useCallback(
    (reason?: string) => {
      if (reason !== "clickaway") {
        appContext.setReloadPage(true);
      }
    },
    [appContext]
  );

  React.useEffect(() => {
    const action = (snackbarId: SnackbarKey) => (
      <React.Fragment>
        <Button
          color="secondary"
          size="small"
          onClick={(event: React.SyntheticEvent | Event, reason?: string) => handleUpdateReload(reason)}
        >
          {translate("UPDATE_NOW")}
        </Button>
        <IconButton
          size="small"
          color="inherit"
          onClick={(event: React.SyntheticEvent | Event, reason?: string) => handleClose(snackbarId, reason)}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </React.Fragment>
    );

    if (appContext.showReload) {
      appContext.setShowReload(false);
      console.info(translate("NEW_APP_VERSION_AVAILABLE"));
      enqueueSnackbar(translate("NEW_APP_VERSION_AVAILABLE"), {
        variant: "info",
        persist: true,
        preventDuplicate: true,
        action: action,
      });
    }
  }, [appContext, enqueueSnackbar, handleUpdateReload, handleClose]);

  return <React.Fragment />;
};
