import React from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { useAuthenticator } from "@aws-amplify/ui-react";

import BottomNavigation from "@mui/material/BottomNavigation";
import BottomNavigationAction from "@mui/material/BottomNavigationAction";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";

import { xsFlex } from "../Breakpoints";
import { MenuEntry, NavPages } from "./Common";

export const FixedBottomNavigation = (): JSX.Element => {
  const { route } = useAuthenticator((context) => [context.route, context.signOut]);
  const location = useLocation();
  const navigate = useNavigate();

  const ref = React.useRef<HTMLDivElement>(null);

  const pages: MenuEntry[] = NavPages();

  const navValue = pages.findIndex((item) => item.pathname === location?.pathname);

  const handleBottomNavigation = (value: number) => {
    if (value in pages) {
      navigate(pages[value].pathname);
    }
  };

  return (
    <Box sx={{ display: xsFlex }} ref={ref}>
      {route === "authenticated" && (
        <Paper sx={{ position: "fixed", bottom: 0, left: 0, right: 0 }} elevation={3}>
          <BottomNavigation showLabels value={navValue} onChange={(event, value) => handleBottomNavigation(value)}>
            {pages.map((page: MenuEntry, index: number) => (
              <BottomNavigationAction key={index} label={page.display} icon={page.icon} />
            ))}
          </BottomNavigation>
        </Paper>
      )}
    </Box>
  );
};
