export const getLocale = (override: string | undefined = undefined): string | undefined => {
  if (override !== undefined) {
    return override;
  }
  try {
    const language = localStorage.locale || navigator.language;
    return language;
  } catch {
    return undefined;
  }
};

export const getLanguage = (): string => {
  const locale = getLocale();
  if (locale) {
    if (["pl-PL", "pl-US", "en-US"].includes(locale)) {
      return locale;
    } else if (["pl", "en"].includes(locale.substr(0, 2))) {
      return locale.substr(0, 2);
    }
  }
  return "en";
};
