import React from "react";

import { useLocation, useNavigate } from "react-router-dom";

import { translate } from "@aws-amplify/ui";
import { useAuthenticator } from "@aws-amplify/ui-react";

import { styled } from "@mui/material/styles";
import { green, indigo } from "@mui/material/colors";

//import AdbIcon from '@mui/icons-material/Adb'; // App Icon in the feature

import AppBar from "@mui/material/AppBar";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SettingsIcon from "@mui/icons-material/SettingsOutlined";
import Stack from "@mui/material/Stack";
import Toolbar from "@mui/material/Toolbar";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { xsFlex, smFlex } from "../Breakpoints";
import {
  MenuEntry,
  MenuEntryHome,
  MenuEntryHistory,
  MenuEntryAccount,
  MenuEntryDivider,
  MenuEntryLogout,
} from "./Common";
import { AppSettingsDrawer } from "./AppSettingsDrawer";

const APPNAME = "PhoneMed";

const SignInButton = styled(Button)({
  textTransform: "none",
  border: "1px solid",
  color: "darkblue",
  backgroundColor: "white",
  "&:hover": {
    backgroundColor: "white",
  },
  "&:active": {
    backgroundColor: "white",
  },
  "&:focus": {
    backgroundColor: "white",
  },
});

const JoinNowButton = styled(Button)({
  textTransform: "none",
  border: "1px solid",
  color: "white",
  backgroundColor: "black",
  "&:hover": {
    backgroundColor: "black",
  },
  "&:active": {
    backgroundColor: "black",
  },
  "&:focus": {
    backgroundColor: "black",
  },
});

export const ResponsiveAppBar = (): JSX.Element => {
  const { route, signOut } = useAuthenticator((context) => [context.route, context.signOut]);
  const location = useLocation();
  const navigate = useNavigate();

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
  const [settingsDrawwerOpen, setSettingsDrawwerOpen] = React.useState(false);

  const tabsMenu: MenuEntry[] = route !== "authenticated" ? [] : [MenuEntryHome(), MenuEntryHistory()];
  const userMenu: MenuEntry[] = [MenuEntryAccount(), MenuEntryDivider(), MenuEntryLogout()];

  const handleSignIn = () => {
    navigate("/auth/login");
  };

  const handleSignUp = () => {
    navigate("/auth/signup");
  };

  const handleTabMenu = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | React.MouseEvent<HTMLLIElement, MouseEvent>,
    name: string | undefined,
    pathname: string
  ) => {
    navigate(pathname);
  };

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = (
    event: React.MouseEvent<HTMLLIElement, MouseEvent>,
    name: string | undefined,
    pathname: string
  ) => {
    setAnchorElUser(null);
    if (name === "LOGOUT") {
      signOut();
    }
    navigate(pathname);
  };

  const getAppLocation = (): string | undefined => {
    type PageTitle = {
      display: string;
      pathname: string;
    };
    const pages: PageTitle[] = [
      { display: translate("HOME"), pathname: "/account/home" },
      { display: translate("HISTORY"), pathname: "/account/history" },
      { display: translate("ACCOUNT"), pathname: "/account/page" },
      { display: translate("ACCOUNT"), pathname: "/account/profile" },
      { display: translate("ACCOUNT"), pathname: "/account/settings" },
    ];
    return pages.find((item) => item.pathname === location?.pathname)?.display;
  };
  const appLocation = getAppLocation();

  return (
    <React.Fragment>
      <AppBar position="static" enableColorOnDark>
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 1,
                flexGrow: 1,
                fontFamily: "monospace",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              {APPNAME}
            </Typography>

            {appLocation && (
              <Box sx={{ flexGrow: 1, display: xsFlex }}>
                <Typography
                  variant="h5"
                  sx={{
                    mr: 1,
                    color: "inherit",
                    textDecoration: "none",
                  }}
                >
                  {appLocation}
                </Typography>
              </Box>
            )}

            <Box sx={{ flexGrow: 1, display: smFlex }}>
              <Stack direction="row" spacing={2}>
                {tabsMenu.length !== 0 &&
                  tabsMenu.map((tabsMenuEntry: MenuEntry, index: number) => (
                    <Button
                      key={index}
                      variant="contained"
                      disableRipple={true}
                      onClick={(event) => handleTabMenu(event, tabsMenuEntry.name, tabsMenuEntry.pathname)}
                      sx={{ color: "white", display: "block" }}
                    >
                      {tabsMenuEntry.display}
                    </Button>
                  ))}
              </Stack>
            </Box>

            {route !== "idle" && (
              <Stack direction="row" spacing={1}>
                {route !== "authenticated" ? (
                  <React.Fragment>
                    <SignInButton
                      size="small"
                      disableRipple={true}
                      disableElevation={true}
                      onClick={() => handleSignIn()}
                    >
                      {translate("SIGN_IN")}
                    </SignInButton>
                    <JoinNowButton
                      size="small"
                      disableRipple={true}
                      disableElevation={true}
                      onClick={() => handleSignUp()}
                    >
                      {translate("CREATE_ACCOUNT")}
                    </JoinNowButton>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <Box sx={{ flexGrow: 0, display: smFlex }}>
                      <Tooltip title={translate("ACCOUNT")}>
                        <IconButton onClick={(event) => handleOpenUserMenu(event)} sx={{ p: 0 }}>
                          <Avatar sx={{ bgcolor: green[500] }}>
                            <ManageAccountsIcon />
                          </Avatar>
                        </IconButton>
                      </Tooltip>
                      <Menu
                        sx={{ mt: "45px" }}
                        id="menu-appbar"
                        anchorEl={anchorElUser}
                        anchorOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: "top",
                          horizontal: "right",
                        }}
                        open={Boolean(anchorElUser)}
                        onClose={() => setAnchorElUser(null)}
                      >
                        {userMenu.map((userMenuEntry: MenuEntry, index: number) =>
                          userMenuEntry.display ? (
                            <MenuItem
                              key={index}
                              onClick={(event) =>
                                handleCloseUserMenu(event, userMenuEntry.name, userMenuEntry.pathname)
                              }
                            >
                              <Typography textAlign="center">{userMenuEntry.display}</Typography>
                            </MenuItem>
                          ) : (
                            <Divider key={index} />
                          )
                        )}
                      </Menu>
                    </Box>
                  </React.Fragment>
                )}
              </Stack>
            )}
            <Tooltip title={translate("SETTINGS_TOOLTIP")} enterDelay={300}>
              <IconButton onClick={() => setSettingsDrawwerOpen(true)} sx={{ px: "8px" }}>
                <Avatar sx={{ bgcolor: indigo[500] }}>
                  <SettingsIcon />
                </Avatar>
              </IconButton>
            </Tooltip>
          </Toolbar>
        </Container>
      </AppBar>
      <AppSettingsDrawer onClose={() => setSettingsDrawwerOpen(false)} open={settingsDrawwerOpen} />
    </React.Fragment>
  );
};
