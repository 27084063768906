import React from "react";

import { Authenticator } from "@aws-amplify/ui-react";

import { AppContextProvider } from "./components/AppContext";

import { Main } from "./Main";

const App = (): JSX.Element => {
  return (
    <Authenticator.Provider>
      <AppContextProvider>
        <Main />
      </AppContextProvider>
    </Authenticator.Provider>
  );
};

export default App;
