import React from "react";
import ReactDOM from "react-dom";
//import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import reportWebVitals from "./reportWebVitals";

import { I18n } from "aws-amplify";
import { translations } from "@aws-amplify/ui-react";
import { en_US } from "./assets/translates/en";
import { pl_PL } from "./assets/translates/pl";

import { getLanguage } from "./Locale";

import "@aws-amplify/ui-react/styles.css";

import { Amplify } from "aws-amplify";

import "./index.css";
import App from "./App";

import awsExports from "./aws-exports";

Amplify.configure(awsExports);

I18n.setLanguage(getLanguage());
I18n.putVocabularies(translations);

I18n.putVocabulariesForLanguage("en-US", en_US);
I18n.putVocabulariesForLanguage("pl-PL", pl_PL);
I18n.putVocabulariesForLanguage("pl", pl_PL);
I18n.putVocabulariesForLanguage("en", en_US);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
//serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
