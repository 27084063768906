import React from "react";

import { useNavigate } from "react-router-dom";

import { translate } from "@aws-amplify/ui";

import { green } from "@mui/material/colors";

import AddIcon from "@mui/icons-material/Add";
import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

export const Home = (): JSX.Element => {
  const navigate = useNavigate();

  const [anchorElAdd, setAnchorElAdd] = React.useState<null | HTMLElement>(null);

  type MenuEntry = {
    display?: string;
    route?: string;
  };

  const addMenu: MenuEntry[] = [
    { display: translate("ADDMEDICATION"), route: "ADDMEDICATION" },
    { display: translate("ADDDOSE"), route: "ADDDOSE" },
  ];

  const handleOpenAddMenu = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    setAnchorElAdd(event.currentTarget);
  };

  const handleCloseAddMenu = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, name: string | undefined) => {
    setAnchorElAdd(null);
    switch (name) {
      case "ADDMEDICATION":
        navigate("/account/addmedication");
        break;
      case "ADDDOSE":
        navigate("/account/adddose");
        break;
    }
  };

  return (
    <Stack>
      <Typography variant="h6" textAlign="center">
        {translate("MY_MEDICATIONS")}
      </Typography>
      <Box sx={{ flexGrow: 1 }}>
        <Tooltip title="Add">
          <IconButton onClick={(event) => handleOpenAddMenu(event)} sx={{ p: 0 }}>
            <Avatar sx={{ bgcolor: green[500] }}>
              <AddIcon />
            </Avatar>
          </IconButton>
        </Tooltip>
        <Menu
          sx={{ mt: "45px" }}
          id="menu-addbar"
          anchorEl={anchorElAdd}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={Boolean(anchorElAdd)}
          onClose={() => setAnchorElAdd(null)}
        >
          {addMenu.map((setting: MenuEntry, index: number) =>
            setting.route ? (
              <MenuItem key={index} onClick={(event) => handleCloseAddMenu(event, setting.route)}>
                <Typography textAlign="center">{setting.display}</Typography>
              </MenuItem>
            ) : setting.display ? (
              <MenuItem key={index} disabled={true}>
                <Typography textAlign="center">{setting.display}</Typography>
              </MenuItem>
            ) : (
              <Divider key={index} />
            )
          )}
        </Menu>
      </Box>
    </Stack>
  );
};
