import React from "react";

import { useNavigate } from "react-router-dom";
import { useTheme } from "@mui/material/styles";

import Button from "@mui/material/Button";
import KeyboardArrowLeft from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import MobileStepper from "@mui/material/MobileStepper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

type Steps = {
  label: string | React.ReactNode;
  description: string | React.ReactNode;
};

const steps: Steps[] = [
  {
    label: "Select medication",
    description: "Form to select medication that was started",
  },
  {
    label: "Dodaj INR",
    description: "Dodaj INR",
  },
  {
    label: "Select dose",
    description: "Display calculated",
  },
];

export const AddDose = (): JSX.Element => {
  const theme = useTheme();
  const navigate = useNavigate();

  const [activeStep, setActiveStep] = React.useState<number>(0);
  const maxSteps = steps.length;

  const handleSubmit = () => {
    navigate("/account/home");
  };

  const handleNext = () => {
    if (activeStep >= maxSteps - 1) {
      handleSubmit();
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const getNextLabel = (): string => {
    return activeStep === 0 ? "Start" : activeStep >= maxSteps - 1 ? "Finish" : "Next";
  };
  const getBackLabel = (): string => {
    return "Back";
  };
  return (
    <Stack direction="column" justifyContent="space-between" sx={{ height: "300px" }}>
      <Stack alignItems="center">
        <Typography>{steps[activeStep].label}</Typography>
      </Stack>
      <Stack px={2}>{steps[activeStep].description}</Stack>
      <MobileStepper
        variant="text"
        steps={maxSteps}
        position="static"
        activeStep={activeStep}
        nextButton={
          <Button size="small" onClick={handleNext}>
            {getNextLabel()}
            {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
          </Button>
        }
        backButton={
          <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
            {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            {getBackLabel()}
          </Button>
        }
      />
    </Stack>
  );
};
