import React from "react";

import moment, { Moment } from "moment";
import { useFormik } from "formik";
import * as yup from "yup";

import { translate } from "@aws-amplify/ui";

import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import { toAWSDate, fromAWSDate } from "../../utils/dateutils";
import { ClinicalSex } from "../../../models";

export type AccountData = {
  username: string;
  clinicalSex: ClinicalSex;
  firstName: string;
  lastName: string;
  dateOfBirth: string;
};

type FormValues = {
  firstName: string;
  lastName: string;
  clinicalSex: string;
  dateOfBirth: Moment;
};

const initialValues: FormValues = {
  firstName: "",
  lastName: "",
  clinicalSex: "",
  dateOfBirth: moment(),
};

type Props = {
  accountData: AccountData | undefined;
  onSave: (values: AccountData) => void;
  onCancel: () => void;
};

export const SettingsForm = (props: Props): JSX.Element => {
  const { accountData, onSave, onCancel } = props;

  const fromClinicalSex = (value: string): string => {
    switch (value) {
      case ClinicalSex.F:
        return translate("FEMALE_SEX");
        break;
      case ClinicalSex.M:
        return translate("MALE_SEX");
        break;
      default:
        return "";
    }
  };

  const toClinicalSex = (value: string): string => {
    switch (value) {
      case translate("FEMALE_SEX"):
        return ClinicalSex.F;
      case translate("MALE_SEX"):
        return ClinicalSex.M;
      default:
        return "";
    }
  };

  const formValuesFromProps = (values: AccountData | undefined): FormValues => {
    if (values === undefined) {
      return { ...initialValues };
    }
    return {
      username: "",
      firstName: values.firstName,
      lastName: values.lastName,
      clinicalSex: fromClinicalSex(values.clinicalSex),
      dateOfBirth: fromAWSDate(values.dateOfBirth),
    } as FormValues;
  };

  const formValuesToProps = (values: FormValues): AccountData => {
    return {
      username: "",
      firstName: values.firstName,
      lastName: values.lastName,
      clinicalSex: toClinicalSex(values.clinicalSex),
      dateOfBirth: toAWSDate(values.dateOfBirth),
    } as AccountData;
  };

  const clinicalSexChoice = [translate("FEMALE_SEX"), translate("MALE_SEX")];

  const validationSchema = yup.object({
    firstName: yup.string().required(translate("FIRSTNAME_IS_REQUIRED")),
    lastName: yup.string().required(translate("LASTNAME_IS_REQUIRED")),
    clinicalSex: yup.string().required(translate("CLINICALSEX_IS_REQUIRED")),
    dateOfBirth: yup
      .date()
      .max(moment().subtract(18, "years"), translate("DATEOFBIRTH_MUST_BE_18"))
      .required(translate("DATEOFBIRTH_IS_REQUIRED")),
  });

  const formik = useFormik({
    initialValues: formValuesFromProps(accountData),
    validationSchema: validationSchema,
    onSubmit: (values: FormValues) => {
      onSave(formValuesToProps(values));
    },
  });

  const handleDateOfBirthChange = (dateValue: Moment | null): void => {
    if (dateValue && dateValue.isValid()) {
      formik.setFieldValue("dateOfBirth", dateValue);
    }
  };

  return (
    <Box m="auto" component="form" onSubmit={formik.handleSubmit}>
      <Stack sx={{ mt: 2 }}>
        <Stack direction="column" justifyContent="flex-start" alignItems="center" spacing={2} sx={{ mb: 2 }}>
          <TextField
            fullWidth
            id="firstName"
            name="firstName"
            label={translate("FIRST_NAME")}
            value={formik.values.firstName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
            helperText={formik.touched.firstName && formik.errors.firstName ? formik.errors.firstName : " "}
          />
          <TextField
            fullWidth
            id="lastName"
            name="lastName"
            label={translate("LAST_NAME")}
            value={formik.values.lastName}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
            helperText={formik.touched.lastName && formik.errors.lastName ? formik.errors.lastName : " "}
          />
          <FormControl fullWidth>
            <InputLabel id="clinicalSex" error={formik.touched.clinicalSex && !!formik.errors.clinicalSex}>
              {formik.errors.clinicalSex ? formik.errors.clinicalSex : translate("CLINICALSEX")}
            </InputLabel>
            <Select
              {...formik.getFieldProps("clinicalSex")}
              error={formik.touched.clinicalSex && !!formik.errors.clinicalSex}
              id="clinicalSex"
              label={translate("CLINICALSEX")}
            >
              {clinicalSexChoice?.map((el, index) => {
                return (
                  <MenuItem key={index} value={el}>
                    {el}
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText> </FormHelperText>
          </FormControl>
          <MobileDatePicker
            label={translate("DATEOFBIRTH")}
            value={formik.values.dateOfBirth}
            onChange={handleDateOfBirthChange}
            renderInput={(params: any) => (
              <TextField
                fullWidth
                id="dateOfBirth"
                name="dateOfBirth"
                error={formik.touched.dateOfBirth && Boolean(formik.errors.dateOfBirth)}
                helperText={formik.touched.dateOfBirth && formik.errors.dateOfBirth ? formik.errors.dateOfBirth : " "}
                {...params}
              />
            )}
          />
        </Stack>
        <Stack direction="row" justifyContent="flex-end" alignItems="flex-start" spacing={3}>
          <Button color="secondary" variant="outlined" onClick={() => onCancel()}>
            {translate("CANCEL")}
          </Button>
          <Button color="primary" variant="contained" type="submit" disabled={!(formik.isValid && formik.dirty)}>
            {translate("SAVE")}
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};
