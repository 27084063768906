// @ts-check
import { initSchema } from '@aws-amplify/datastore';
import { schema } from './schema';

const ClinicalSex = {
  "M": "M",
  "F": "F"
};

const MeasurementSystem = {
  "M": "M",
  "I": "I"
};

const MedicationStatus = {
  "A": "A",
  "I": "I"
};

const { Doctor, Account, Medication } = initSchema(schema);

export {
  Doctor,
  Account,
  Medication,
  ClinicalSex,
  MeasurementSystem,
  MedicationStatus
};