import React from "react";

import { translate } from "@aws-amplify/ui";

import HomeIcon from "@mui/icons-material/Home";
import HistoryIcon from "@mui/icons-material/History";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import LogoutIcon from "@mui/icons-material/Logout";

export type MenuEntry = {
  display?: string;
  name: string;
  pathname: string;
  icon?: React.ReactNode;
};

export const MenuEntryDivider = (): MenuEntry => {
  return { display: undefined, name: "", pathname: "" };
};
export const MenuEntryHome = (): MenuEntry => {
  return { display: translate("HOME"), name: "HOME", pathname: "/account/home", icon: <HomeIcon /> };
};
export const MenuEntryHistory = (): MenuEntry => {
  return { display: translate("HISTORY"), name: "HISTORY", pathname: "/account/history", icon: <HistoryIcon /> };
};
export const MenuEntryAccount = (): MenuEntry => {
  return { display: translate("ACCOUNT"), name: "ACCOUNT", pathname: "/account/page", icon: <AccountBoxIcon /> };
};
export const MenuEntryLogout = (): MenuEntry => {
  return { display: translate("LOGOUT"), name: "LOGOUT", pathname: "/", icon: <LogoutIcon /> };
};

export const NavPages = (): MenuEntry[] => {
  return [MenuEntryHome(), MenuEntryHistory(), MenuEntryAccount()];
};
