import React from "react";

import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

export const useServiceWorker = () => {
  const [waitingWorker, setWaitingWorker] = React.useState<ServiceWorker | null>(null);
  const [showReload, setShowReload] = React.useState<boolean>(false);

  // called when a service worker
  // updates. this function is a callback
  // to the actual service worker
  // registration onUpdate.
  const onSWUpdate = React.useCallback((registration: ServiceWorkerRegistration) => {
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  }, []);

  // register the service worker
  React.useEffect(() => {
    // If you want your app to work offline and load faster, you can change
    // unregister() to register() below. Note this comes with some pitfalls.
    // Learn more about service workers: https://cra.link/PWA
    serviceWorkerRegistration.register({
      onUpdate: onSWUpdate,
    });
  }, [onSWUpdate]);

  const reloadAndClearCache = async () => {
    if (caches) {
      const names = await caches.keys();
      await Promise.all(names.map((name) => caches.delete(name)));
    }
    window.location.reload();
  };

  // simply put, this tells the service
  // worker to skip the waiting phase and then reloads the page
  const reloadPage = React.useCallback(async () => {
    if (waitingWorker !== undefined) {
      waitingWorker?.postMessage({ type: "SKIP_WAITING" });
      setShowReload(false);
      await reloadAndClearCache();
    }
  }, [waitingWorker]);

  const haveUpdate = (): boolean => {
    if (showReload && waitingWorker) {
      return true;
    }
    return false;
  };

  const clrUpdate = (): void => {
    setShowReload(false);
  };

  return { haveUpdate, clrUpdate, reloadPage };
};
