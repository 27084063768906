import React from "react";

import { useNavigate } from "react-router-dom";

import { useSnackbar } from "notistack";

import { translate } from "@aws-amplify/ui";

import Box from "@mui/material/Box";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { useAppContext } from "../../../components/AppContext";
import { Account, LazyAccount, Doctor } from "../../../models";
import { loadAccount, updateAccount } from "../../DataSync";
import { smFlex, xsFlex } from "../../Breakpoints";
import { SettingsForm, AccountData } from "./SettingsForm";

export const SettingsPage: React.FC = (): JSX.Element => {
  const appContext = useAppContext();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = React.useState<boolean>(true);
  const [account, setAccount] = React.useState<Account | undefined>(undefined);
  const [doctor, setDoctor] = React.useState<Doctor | undefined>(undefined);

  const handleBack = React.useCallback((): void => {
    navigate("/account/page");
  }, [navigate]);

  React.useEffect(() => {
    const queryData = async (username: string) => {
      try {
        const { accountRecord, doctorRecord } = await loadAccount(username);
        setAccount(accountRecord);
        setDoctor(doctorRecord);
      } catch (err) {
        console.info("FAILED to load account");
        enqueueSnackbar("Failed to load settings", {
          key: Math.random().toString(36).substring(1, 20),
          variant: "error",
        });
        setAccount(undefined);
        setDoctor(undefined);
      } finally {
        setLoading(false);
      }
    };
    if (appContext.username !== undefined && loading && doctor === undefined && account === undefined) {
      queryData(appContext.username);
    }
  }, [appContext, loading, doctor, account, setAccount, enqueueSnackbar]);

  const save = React.useCallback(
    async (username: string, email: string, values: LazyAccount) => {
      try {
        if (doctor !== undefined) {
          await updateAccount(username, email, account, values, doctor);
        }
      } catch (err) {
        console.info("FAILED to save account values=%s err=%s", JSON.stringify(values, null, 2), err);
        enqueueSnackbar("Failed to save settings", { variant: "error" });
      } finally {
        handleBack();
      }
    },
    [doctor, account, enqueueSnackbar, handleBack]
  );

  const handleSave = (values: AccountData): void => {
    if (appContext.username !== undefined && appContext.email !== undefined && doctor !== undefined) {
      save(appContext.username, appContext.email, { ...values } as LazyAccount);
    }
  };

  return (
    <Stack alignItems="center" sx={{ m: 1 }}>
      <Stack sx={{ maxWidth: "20rem" }}>
        <Box sx={{ display: smFlex }}>
          <Box>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" component="button" onClick={(event) => handleBack()}>
                {translate("ACCOUNT")}
              </Link>
              <Typography color="text.primary">{translate("ACCOUNT_SETTINGS")}</Typography>
            </Breadcrumbs>
            <Typography variant="h5">
              <strong>{translate("ACCOUNT_SETTINGS")}</strong>
            </Typography>
          </Box>
        </Box>
        <Box sx={{ display: xsFlex }}>
          <Box>
            <Breadcrumbs aria-label="breadcrumb">
              <Link underline="hover" color="inherit" component="button" onClick={(event) => handleBack()}>
                <strong>{"<"}</strong>
              </Link>
            </Breadcrumbs>
            <Typography variant="h5">
              <strong>{translate("ACCOUNT_SETTINGS")}</strong>
            </Typography>
          </Box>
        </Box>

        {!loading && (
          <SettingsForm accountData={account as AccountData} onSave={handleSave} onCancel={() => handleBack()} />
        )}
      </Stack>
    </Stack>
  );
};
