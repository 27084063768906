import React from "react";

import { DataStore } from "aws-amplify";
import { Doctor, Account, Medication } from "../models";

import { useAppContext } from "./AppContext";

type Props = {
  username: string;
};

export const DataMonitor = (props: Props): JSX.Element => {
  const { username } = props;
  const appContext = useAppContext();

  React.useEffect(() => {
    const sub = DataStore.observeQuery(Doctor).subscribe(({ items }) => {
      console.info("Latest Doctor %s", JSON.stringify(items, null, 2));
      //appContext.setDoctor(items[0]);
    });
    return () => {
      sub.unsubscribe();
    };
  }, [username, appContext]);

  React.useEffect(() => {
    const sub = DataStore.observeQuery(Account, (c) => c.username.eq(username)).subscribe(({ items }) => {
      console.info("Latest Account %s", JSON.stringify(items, null, 2));
      //appContext.setAccount(items[0]);
    });
    return () => {
      sub.unsubscribe();
    };
  }, [username, appContext]);

  React.useEffect(() => {
    const sub = DataStore.observeQuery(Medication).subscribe(({ items }) => {
      console.info("Latest Medication %s", JSON.stringify(items, null, 2));
      //appContext.setMedication(items);
    });
    return () => {
      sub.unsubscribe();
    };
  }, [appContext]);

  return <React.Fragment />;
};
