import React from "react";

import { translate } from "@aws-amplify/ui";
import { useAuthenticator } from "@aws-amplify/ui-react";

import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Unstable_Grid2";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import PersonIcon from "@mui/icons-material/Person";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";

import { useAppContext } from "../../AppContext";

import { smFlex } from "../../Breakpoints";

import { CardEntry, Card } from "./Card";

import { clearDatastore, deleteAllRecords, addDefaultDoctor } from "../../../components/DataSync";

const style = {
  width: "100%",
  maxWidth: 360,
  bgcolor: "background.paper",
};

export const Page: React.FC = (): JSX.Element => {
  const appContext = useAppContext();
  const { signOut } = useAuthenticator((context) => [context.signOut]);

  const appVersion = translate("APP_VERSION") + ` ` + appContext.version;

  const links: CardEntry[] = [
    {
      icon: <PersonIcon />,
      title: translate("PROFILE"),
      description: translate("PROFILE_DESCRIPTION"),
      pathname: "/account/profile",
    },
    {
      icon: <ManageAccountsIcon />,
      title: translate("SETTINGS"),
      description: translate("SETTINGS_DESCRIPTION"),
      pathname: "/account/settings",
    },
  ];

  const reloadAndClearCache = async () => {
    if (caches) {
      const names = await caches.keys();
      await Promise.all(names.map((name) => caches.delete(name)));
    }
    window.location.reload();
  };

  const clearCache = async () => {
    await deleteAllRecords();
    await addDefaultDoctor();
    await clearDatastore();
  };

  return (
    <Stack sx={{ m: 1 }}>
      <Stack justifyContent="center" alignItems="center" spacing={0} sx={{ display: smFlex }}>
        <Typography variant="h6">{translate("ACCOUNT")}</Typography>
      </Stack>
      <Grid container sx={{ m: 2 }} spacing={{ xs: 2, sm: 4, md: 8 }} columns={{ xs: 1, sm: 4, md: 4 }}>
        {links.map((item: CardEntry, index: number) => (
          <Grid key={index} xs={1} sm={2} md={2}>
            <Card icon={item.icon} title={item.title} description={item.description} pathname={item.pathname} />
          </Grid>
        ))}
      </Grid>
      <Divider />
      <List sx={style} component="nav">
        <ListItem button onClick={() => signOut()}>
          <ListItemText primary={translate("LOGOUT")} />
        </ListItem>
        <Divider light />
        <ListItem button onClick={() => reloadAndClearCache()}>
          <ListItemText primary={appVersion} />
        </ListItem>
        <ListItem button onClick={() => clearCache()}>
          <ListItemText primary={translate("CLEAR_DATASTORE")} />
        </ListItem>
      </List>
    </Stack>
  );
};
