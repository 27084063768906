import React from "react";

import { useNavigate } from "react-router-dom";

import { styled } from "@mui/material/styles";

import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";

const CardIcon = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  padding: theme.spacing(0.5),
  textAlign: "left",
}));

const CardTitle = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.h6,
  padding: theme.spacing(0.5),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

const CardDescription = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body1,
  padding: theme.spacing(0.5),
  textAlign: "left",
  color: theme.palette.text.secondary,
}));

export type CardEntry = {
  icon: React.ReactNode;
  title: string;
  description: string;
  pathname: string;
};

export const Card: React.FC<CardEntry> = (props: CardEntry): JSX.Element => {
  const { icon, title, description, pathname } = props;
  const navigate = useNavigate();
  return (
    <Link
      underline="none"
      color="inherit"
      component="button"
      sx={{ minWidth: "100%" }}
      onClick={(event) => navigate(pathname)}
    >
      <Paper>
        <CardIcon>{icon}</CardIcon>
        <CardTitle>
          <strong>{title}</strong>
        </CardTitle>
        <CardDescription>{description}</CardDescription>
      </Paper>
    </Link>
  );
};
