import React from "react";

import { useFormik } from "formik";
import * as yup from "yup";

import { translate } from "@aws-amplify/ui";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import InputAdornment from "@mui/material/InputAdornment";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";

import { MeasurementSystem } from "../../../models";

export type ProfileData = {
  username: string;
  measurementSystem: MeasurementSystem;
  weight: number;
  height: number;
};

type FormValues = {
  measurementSystem: string;
  weight: string;
  height: string;
};

const initialValues: FormValues = {
  measurementSystem: MeasurementSystem.M.toString(),
  weight: "0",
  height: "0",
};

type Props = {
  profileData: ProfileData | undefined;
  onSave: (values: ProfileData) => void;
  onCancel: () => void;
};

export const ProfileForm = (props: Props): JSX.Element => {
  const { profileData, onSave, onCancel } = props;

  const fromMeasurementSystemType = (value: string): string => {
    switch (value) {
      case MeasurementSystem.M:
        return MeasurementSystem.M.toString();
        break;
      case MeasurementSystem.I:
        return MeasurementSystem.I.toString();
        break;
      default:
        return "";
    }
  };

  const toMeasurementSystemType = (value: string): string => {
    switch (value) {
      case MeasurementSystem.M.toString():
        return MeasurementSystem.M;
      case MeasurementSystem.I.toString():
        return MeasurementSystem.I;
      default:
        return "";
    }
  };

  const formValuesFromProps = (values: ProfileData | undefined): FormValues => {
    if (values === undefined) {
      return { ...initialValues };
    }
    return {
      measurementSystem: fromMeasurementSystemType(values.measurementSystem),
      weight: values.weight.toFixed(0),
      height: values.height.toFixed(0),
    } as FormValues;
  };

  const formValuesToProps = (values: FormValues): ProfileData => {
    return {
      measurementSystem: toMeasurementSystemType(values.measurementSystem),
      weight: parseFloat(values.weight),
      height: parseFloat(values.height),
    } as ProfileData;
  };

  const validationSchema = yup.object({
    weight: yup
      .number()
      .typeError(translate("MUST_SPECIFY_NUMBER"))
      .positive(translate("MUST_BE_MORE_THEN_0"))
      .required(translate("WEIGHT_REQUIRED")),
    height: yup
      .number()
      .typeError(translate("MUST_SPECIFY_NUMBER"))
      .positive(translate("MUST_BE_MORE_THEN_0"))
      .required(translate("HEIGHT_IS_REQUIRED")),
  });

  const formik = useFormik({
    initialValues: formValuesFromProps(profileData),
    validationSchema: validationSchema,
    onSubmit: (values: FormValues) => {
      onSave(formValuesToProps(values));
    },
  });

  const selectMeasurmentSystem = (event: React.ChangeEvent<HTMLInputElement>, value: string) => {
    formik.setFieldValue("measurementSystem", value);
  };

  const weightUnit = formik.values.measurementSystem === MeasurementSystem.M.toString() ? "kg" : "lbs";
  const heightUnit = formik.values.measurementSystem === MeasurementSystem.M.toString() ? "cm" : "in";

  return (
    <Box m="auto" component="form" onSubmit={formik.handleSubmit}>
      <Stack sx={{ mt: 2 }}>
        <Stack direction="column" justifyContent="flex-start" alignItems="center" spacing={2} sx={{ mb: 2 }}>
          <FormControl fullWidth component="fieldset">
            <FormLabel component="legend">{translate("SELECT_MEASUREMENT_SYSTEM")}</FormLabel>
            <RadioGroup
              row
              id="measurementSystem"
              name="measurementSystem"
              value={formik.values.measurementSystem.toString()}
              onChange={selectMeasurmentSystem}
            >
              <FormControlLabel
                control={<Radio />}
                label={translate("METRIC")}
                value={MeasurementSystem.M.toString()}
              />
              <FormControlLabel
                control={<Radio />}
                label={translate("IMPERIAL")}
                value={MeasurementSystem.I.toString()}
              />
            </RadioGroup>
          </FormControl>
          <TextField
            fullWidth
            id="weight"
            name="weight"
            label={translate("WEIGHT")}
            margin="normal"
            InputProps={{
              endAdornment: <InputAdornment position="end">{weightUnit}</InputAdornment>,
            }}
            value={formik.values.weight}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.weight && Boolean(formik.errors.weight)}
            helperText={formik.touched.weight && formik.errors.weight ? formik.errors.weight : " "}
          />
          <TextField
            fullWidth
            id="height"
            name="height"
            label={translate("HEIGHT")}
            margin="normal"
            InputProps={{
              endAdornment: <InputAdornment position="end">{heightUnit}</InputAdornment>,
            }}
            value={formik.values.height}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={formik.touched.height && Boolean(formik.errors.height)}
            helperText={formik.touched.height && formik.errors.height ? formik.errors.height : " "}
          />
        </Stack>
        <Stack direction="row" justifyContent="flex-end" alignItems="flex-start" spacing={3}>
          <Button color="secondary" variant="outlined" onClick={() => onCancel()}>
            {translate("CANCEL")}
          </Button>
          <Button color="primary" variant="contained" type="submit" disabled={!(formik.isValid && formik.dirty)}>
            {translate("SAVE")}
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
};
