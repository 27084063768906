export const en_US = {
  APP_VERSION: "Application version",
  NEW_APP_VERSION_AVAILABLE: "A new version of this App is available",
  UPDATE_NOW: "UPDATE NOW",
  CLEAR_DATASTORE: "Clear data store",

  CANCEL: "Cancel",
  SAVE: "Save",
  HOME: "Home",
  DASHBOARD: "Dashboard",
  HISTORY: "History",
  ACCOUNT: "My Account",
  PROFILE: "My Profile",
  PROFILE_TOOLTIP: "Profile & Account",
  SETTINGS: "Settings",
  SETTINGS_TOOLTIP: "Toggle Setting Drawer",
  LOGOUT: "Logout",
  CREATE_ACCOUNT: "Create Account",
  SIGN_IN: "Sign in",
  ADDMEDICATION: "Add Medication",
  ADDDOSE: "Add Dose",

  MY_MEDICATIONS: "My medications",
  MY_MEDICATIONS_HISTORY: "My medications history",

  PROFILE_SETTINGS: "Profile Settings",
  ACCOUNT_SETTINGS: "Account Settings",
  ACCOUNT_PAGE: "Account",

  PROFILE_DESCRIPTION: "Provide information about weight and height",
  SETTINGS_DESCRIPTION: "Provide personal details about you",

  MODE: "Mode",
  LIGHT_MODE: "Light mode",
  SYSTEM_PREFERENCES: "System preferences",
  DARK_MODE: "Dark mode",
  LIGHT_MODE_SHORT: "Light",
  SYSTEM_PREFERENCES_SHORT: "System",
  DARK_MODE_SHORT: "Dark",

  SUBMIT: "Submit",

  INTRODUCTION: "Introduction",
  BODY: "The normal clotting mechanism is a complex process that involves multiple substances (clotting factors). These factors are produced by the liver and act in sequence to form a blood clot. In order for the liver to produce some of the clotting factors, adequate amounts of vitamin K must be available. Warfarin blocks one of the enzymes that uses vitamin K to make some of the clotting factors, and in turn reduces their ability to work correctly in the blood. As a result, the clotting mechanism is disrupted and it takes longer for the blood to clot.",

  IF_LEARN_MORE: "If you want to learn more.",
  PLEASE: "Please",
  I_AGREE_TERMS_CONDITIONS: "I agree with the Terms & Conditions",
  MUST_AGREE_TERMS_CONDITIONS: "You must agree to the Terms & Conditions",

  // ProfileForm
  HEIGHT_IS_REQUIRED: "Height is required",
  HEIGHT: "Height",
  MEASUREMENT_SYSTEM_IS_REQUIRED: "Measurement system is required",
  SELECT_MEASUREMENT_SYSTEM: "Select measurement system",
  METRIC: "Metryczny",
  MUST_BE_MORE_THEN_0: "Must be more then 0",
  MUST_SPECIFY_NUMBER: "Must specify a number",
  IMPERIAL: "Imperialny",
  WEIGHT_REQUIRED: "Weight is required",
  WEIGHT: "Weight",

  // SettingsForm
  FIRSTNAME_IS_REQUIRED: "First name is required",
  LASTNAME_IS_REQUIRED: "Last name is required",
  CLINICALSEX_IS_REQUIRED: "Clinical sex is required",
  DATEOFBIRTH_IS_REQUIRED: "Date of birth is required",
  DATEOFBIRTH_MUST_BE_18: "You must be 18 years old",
  FEMALE_SEX: "Female",
  MALE_SEX: "Male",
  FIRST_NAME: "First name",
  LAST_NAME: "Last name",
  CLINICALSEX: "Clinical sex",
  DATEOFBIRTH: "Date of birth",
};
