import React from "react";
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import { SnackbarProvider } from "notistack";

import { DataStore, Hub } from "aws-amplify";
import { useAuthenticator } from "@aws-amplify/ui-react";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { enUS, plPL } from "@mui/x-date-pickers";

import { ThemeProvider, createTheme, Theme } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import useMediaQuery from "@mui/material/useMediaQuery";

import { useAppContext } from "./components/AppContext";
import { VersionMonitor } from "./components/VersionMonitor";

import { DataMonitor } from "./components/DataMonitor";

import { routes } from "./Routes";
import { getLocale } from "./Locale";
import { useServiceWorker } from "./useServiceWorker";

const getLocalization = () => {
  const locale = getLocale();
  if (locale) {
    if (locale.startsWith("pl")) {
      return plPL;
    }
  }
  return enUS;
};

const router = createBrowserRouter(routes);

export const Main = (): JSX.Element => {
  const appContext = useAppContext();
  const { haveUpdate, clrUpdate, reloadPage } = useServiceWorker();
  const { user, route } = useAuthenticator((context) => [context.route]);
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const colorMode = appContext.colorMode;
  const locale = getLocalization();

  React.useEffect(() => {
    if (appContext.firstTime) {
      console.info("firstTime");
      // handle very first time
      appContext.clearFirstTime();
      // delay initial help
      setTimeout(() => {
        // Open somthing
      }, 500);
    } else if (appContext.justLoaded) {
      console.info("justLoaded");
      // handle reload / refresh
      appContext.clearJustLoaded();
    }
  }, [appContext]);

  const theme = React.useMemo((): Theme => {
    return createTheme(
      {
        palette: {
          mode: colorMode !== "system" ? colorMode : prefersDarkMode ? "dark" : "light",
        },
      },
      locale
    );
  }, [colorMode, prefersDarkMode, locale]);

  const appVersion = React.useMemo((): string => {
    return `${process.env.REACT_APP_VERSION}`;
  }, []);

  React.useEffect(() => {
    if (appContext.version !== appVersion) {
      console.info("newVersion %s", appVersion);
      appContext.setVersion(appVersion);
    }
  }, [appContext, appVersion]);

  React.useEffect(() => {
    if (haveUpdate()) {
      console.info("setShowReload");
      clrUpdate();
      appContext.setShowReload(true);
    }
  }, [appContext, haveUpdate, clrUpdate]);

  React.useEffect(() => {
    if (appContext.reloadPage) {
      console.info("reloadPage for update");
      reloadPage();
    }
  }, [appContext, reloadPage]);

  React.useEffect(() => {
    console.info("route = %s", route);
  }, [route]);

  React.useEffect(() => {
    if (user?.username && appContext.username !== user.username) {
      console.info("username=%s", JSON.stringify(user.username, null, 2));
      appContext.setUsername(user.username);
    }
    if (user?.attributes?.email && appContext.email !== user.attributes.email) {
      console.info("email=%s", JSON.stringify(user.attributes.email, null, 2));
      appContext.setEmail(user.attributes.email);
    }
    if (user?.attributes?.phone_number && appContext.phoneNumber !== user.attributes.phone_number) {
      console.info("phone_number=%s", JSON.stringify(user.attributes.phone_number, null, 2));
      appContext.setPhoneNumber(user.attributes.phone_number);
    }
  }, [user, appContext]);

  Hub.listen("auth", async (data) => {
    if (data.payload.event === "signOut") {
      try {
        appContext.clearData();
        await DataStore.clear().then(() => {
          console.info("DataStore clear done");
        });
      } catch (err) {
        console.info("clearDatastore error=%s", err);
      }
    }
  });

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <CssBaseline>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
            autoHideDuration={3000}
          >
            <VersionMonitor />
            {route === "authenticated" && user?.username !== undefined && <DataMonitor username={user?.username} />}
            <RouterProvider router={router} />
          </SnackbarProvider>
        </CssBaseline>
      </LocalizationProvider>
    </ThemeProvider>
  );
};
